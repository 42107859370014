import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { IConfiguration, ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import type { IConfig } from "@microsoft/applicationinsights-common";

type ApplicationInsightOriConfig = IConfig &
    IConfiguration & {
        oriDisablePageViewTracking: boolean;
    };
type RawConfig = Partial<ApplicationInsightOriConfig> & {
    allowAppInsights: boolean;
};

declare global {
    interface Window {
        oriAppInsightsSettings?: RawConfig;
    }
}

let appInsights: ApplicationInsights;

/**
 * Ignore this annoying error caused by FB crawlers:
 * https://github.com/aFarkas/lazysizes/issues/520
 */
function filterOutFbGarbage(item: ITelemetryItem) {
    const params = new URLSearchParams(location.search);
    const fbclidCheck = params.get("fbclid");
    const isIllegalInvocationException = item.data?.message.indexOf("Illegal invocation") > -1;

    return !(fbclidCheck && isIllegalInvocationException);
}

function init() {
    const options = window && window.oriAppInsightsSettings;
    const {
        allowAppInsights,
        connectionString,
        instrumentationKey,
        oriDisablePageViewTracking,
        ...rawConfig
    } = options ?? {};

    // not allowed by config
    if (!allowAppInsights) {
        return;
    }
    // already initialized
    if (appInsights) {
        return;
    }

    const config = {
        ...rawConfig,
        connectionString,
        instrumentationKey: connectionString ? undefined : instrumentationKey,
        crossOrigin: "anonymous",
    };
    appInsights = new ApplicationInsights({ config });
    appInsights.loadAppInsights();
    // Do some filtering of unwanted stuff.
    appInsights.addTelemetryInitializer(filterOutFbGarbage);
    // Manually call trackPageView to establish the current user/session/pageview.
    if (!oriDisablePageViewTracking) {
        appInsights.trackPageView();
    }
}

init();
